import React from 'react'
import { graphql } from 'gatsby'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import { SEO, Layout, ProjectTitleBlock, MainImageBlock, DescriptionBlock, ImageBlock } from 'Components'

dayjs.extend(localizedFormat)

export const educationQuery = graphql`
  query($slug: String!) {
    education: contentfulEducation(slug: { eq: $slug }) {
      slug
      name
      author
      publishDate
      mainImage {
        title
        description
        fluid(maxWidth: 2400, quality: 85) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      seo {
        id
        title
        description
        image {
          title
          description
          fixed(width: 1200, height: 630) {
            width
            height
            src
          }
        }
      }
      imageBlock1 {
        title
        description
        fluid(maxWidth: 2400, quality: 85) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      imageBlock2 {
        title
        description
        fluid(maxWidth: 2400, quality: 85) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      descriptionBlock1 {
        descriptionBlock1
      }
      descriptionBlock2 {
        descriptionBlock2
      }
      pdf {
        file {
          url
        }
      }
    }
  }
`

const Education = ({ data }) => {
  const { education } = data

  const {
    slug,
    seo,
    name,
    publishDate,
    mainImage,
    imageBlock1,
    imageBlock2,
    descriptionBlock1: { descriptionBlock1 },
    descriptionBlock2: { descriptionBlock2 },
    pdf,
  } = education

  return (
    <>
      <SEO pathname={`/${slug}`} title={seo.title} description={seo.description} image={seo.image.fixed.src} />
      <Layout>
        <ProjectTitleBlock name={name} publishDate={dayjs(publishDate).format('LL')} />
        <MainImageBlock title={mainImage.title} description={mainImage.description} fluid={mainImage.fluid} />
        <DescriptionBlock description={descriptionBlock1} />

        <ImageBlock block={imageBlock1} />

        <DescriptionBlock description={descriptionBlock2} />

        <ImageBlock block={imageBlock2} />

        <div className="PDFLink">
          <a href={pdf.file.url} target="_blank" rel="noreferrer">
            Read Full Paper
          </a>
        </div>
      </Layout>
    </>
  )
}

export default Education
